import React from "react";
import { CAROUSEL } from "../../asset";
import { Carousel } from "react-responsive-carousel";

export default function Partners() {
  const array = [
    {
      id: 1,
      img: CAROUSEL.slider1,
    },
    {
      id: 2,
      img: CAROUSEL.slider2,
      
    },
    {
      
      id: 3,
      img: CAROUSEL.slider3,
    },
    {
      id: 4,
      img: CAROUSEL.slider4,
    },
    {
      id: 5,
      img: CAROUSEL.slider5,
    },
    {
      id: 6,
      img: CAROUSEL.slider6,
    },
    {
      id: 7,
      img: CAROUSEL.slider7,
    },
  ];
  return (
    <div className="w-full h-auto ">
      <Carousel
        className="w-full h-full"
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        transitionTime={700}
        useKeyboardArrows={true}
      >
        {array.map((data) => (
          <div className="h-auto lg:h-[82vh] relative w-full " key={data.id}>
          {/* <div className="h-auto  lg:h-[57vh] relative w-full " key={data.id}> */}
            {/* <div className="h-auto relative w-full " key={data.id}> */}
            <div className=" w-full h-full lg:block hidden ">
              <img
                src={data.img}
                alt="carosel"
                className="w-full h-full object-contain"
              />
            </div>
            <div className=" w-full h-full lg:hidden block">
              <img
                src={data.img}
                alt="carosel"
                className="w-full h-full object-fit"
              />
            </div>
            {/* <div className="absolute w-full h-[100%] top-0 bg-black opacity-40"></div>
            <div className="absolute w-full top-0 h-full flex flex-col justify-center items-end">
              <p className="text-white text-[10px]  sm:text-[15px] md:text-3xl 2xl:text-[4vh] font-bold pr-7 w-[90%] md:w-[60%] text-end  lg:mb-1">
                {data.txt1}
              </p>
              <p className="text-white  text-[10px]  sm:text-[15px] md:text-3xl  2xl:text-[4vh] font-bold pr-7 w-[90%] md:w-[60%] text-end  lg:mb-1">
                {data.txt2}
              </p>
              <p className="text-white  text-[10px]  sm:text-[15px] md:text-3xl  2xl:text-[4vh] font-bold pr-7 w-[90%] md:w-[60%] text-end  lg:mb-1">
                {data.txt3}
              </p>
            </div> */}
          </div>
        ))}
      </Carousel>
    </div>
  );
}
