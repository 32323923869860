import React from "react";
import { IMG } from "../../../asset";

export default function Team() {
  const array = [
    {
      id: 1,
      img: IMG.us1,
      name: "Rocky  Robbins",
      desp: "Operations Mgmt / Bus Dev",
      desp2: "MBA, BS Petroleum Engineering",
      // desp2: "Business Development",
      // desp3: "MBA, B.S Petroleum Engineering",
    },
    {
      id: 2,
      img: IMG.us2,
      name: "Sam Pannunzio",
      desp: "Operations / Strategy Mgmt,",
      desp2: "MBA BS Chemical Engineering",
      desp3: "",
    },
    {
      id: 3,
      img: IMG.us3,
      name: "Ekaterina Robbins",
      desp: "BS Education, GIA Appraiser",
      desp2: "",
      desp3: "",
    },
    {
      id: 4,
      img: IMG.us4,
      name: "Sam Boyar",
      desp: "Finance / Oil & Gas / Asset Mgmt. ",
      desp2: "MBA BS Geological Sciences",
      desp3: "",
    },
    {
      id: 5,
      img: IMG.us5,
      name: "Jerad Bloom",
      desp: "Executive Leadership Finances ",
      desp2: "BS Mathematics and Economics, MBA",
      desp3: "",
    },
    {
      id: 6,
      img: IMG.us6,
      name: "Thomas Golczynski",
      desp: "Founder, Advisor",
      desp2: "BS Chemical Engineering",
      desp3: "",
    },
    {
      id: 7,
      img: IMG.us7,
      name: "Brad Caldwell",
      desp: "Executive Leadership Energy",
      desp2: "BS Chemical Engineering, MBA",
      desp3: "",
    },
  ];
  const array2 = [
    {
      id: 1,
      img: IMG.use1,
      name: "Christopher Calk",
      desp: "Strategy / Operations",
      desp2: "BS in Economics, MBA ",
      // desp2: "Business Development",
      // desp3: "MBA, B.S Petroleum Engineering",
    },
    {
      id: 2,
      img: IMG.use2,
      name: "James Duval",
      desp: "Finance / Strategy",
      desp2: "BS in Business Administration, MBA",
      desp3: "",
    },
    {
      id: 3,
      img: IMG.use3,
      name: "Daniel Beacom",
      desp: "Executive Leadership Insurance",
      desp2: "BS Legal Studies",
      desp3: "",
    },
  ];
  return (
    <div className="bg-re h-auto w-full flex flex-col justify-center items-center md:mb-0">
      <div className="text-center lg:text-start w-full h-full">
        <div className="w-full border-t-2 border-dark flex justify-center mb-[4px] md:mb-[1px] lg:mb-[4px] mt-[10px] md:mt-0">
          <p className="text-white bg-dark font-black w-[200px] text-[17px] md:text-[12px] xl:text-[17px] text-center 2xl:text-[2vh] 2xl:w-[18vh] whitespace-nowrap">
            TEI Houston
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 px-[55px] w-full 2xl:gap-13 gap-5 pt-2 pb-7">
          {array.map((data) => (
            <div
              className=" w-full mt-4 md:mt-0 flex justify-center"
              key={data.id}
            >
              <div className="w-full flex items-center  flex-col lg:flex-row lg:items-start">
                <img
                  src={data.img}
                  alt="user"
                  className="p-1 border-gray border-[1px] w-[102px] h-[102px] md:w-[52px] md:h-[52px] lg:w-[72px] lg:h-[72px] 2xl:h-[12vh] 2xl:w-[12vh] rounded-full object-cover€"
                />
                <div className=" w-full h-full flex flex-col justify-center pl-2">
                  <p className="text-dark font-black text-[17px] md:text-[15px] xl:text-[17px] 2xl:text-[2vh]">
                    {data.name}
                  </p>
                  <p className="w-full text-dark text-[13px] md:text-[9px] lg:text-[10px] xl:text-[13px] font-semibold">
                    {data.desp}
                  </p>
                  <p className="w-full text-dark text-[13px] md:text-[9px] lg:text-[10px] xl:text-[13px] font-semibold">
                    {data.desp2}
                  </p>
                  <p className="w-full text-dark text-[13px] md:text-[9px] lg:text-[10px] xl:text-[13px] font-semibold">
                    {data.desp3}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>


      <div className="text-center lg:text-start w-full h-full">
        <div className="w-full border-t-2 border-dark flex justify-center mb-[4px] md:mb-[1px] lg:mb-[4px] mt-[10px] md:mt-0">
          <p className="text-white bg-dark font-black w-[200px] text-[17px] md:text-[12px] xl:text-[17px] text-center 2xl:text-[2vh] 2xl:w-[18vh] whitespace-nowrap">
            TEI Dallas
          </p>
        </div>
        <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 place-items-center px-[55px] w-full 2xl:gap-72 gap-5 pt-2 pb-7">
          {array2.map((data) => (
            <div
              className=" w-full mt-4 md:mt-0 flex justify-center"
              key={data.id}
            >
              <div className="w-full flex items-center  flex-col lg:flex-row lg:items-start">
                <img
                  src={data.img}
                  alt="user"
                  className="p-1 border-gray border-[1px] w-[102px] h-[102px] md:w-[52px] md:h-[52px] lg:w-[72px] lg:h-[72px] 2xl:h-[12vh] 2xl:w-[12vh] rounded-full "
                />
                <div className=" w-full h-full flex flex-col justify-center pl-2">
                  <p className="text-dark font-black text-[17px] md:text-[15px] xl:text-[17px] 2xl:text-[2vh]">
                    {data.name}
                  </p>
                  <p className="w-full text-dark text-[13px] md:text-[9px] lg:text-[10px] xl:text-[13px] font-semibold">
                    {data.desp}
                  </p>
                  <p className="w-full text-dark text-[13px] md:text-[9px] lg:text-[10px] xl:text-[13px] font-semibold">
                    {data.desp2}
                  </p>
                  <p className="w-full text-dark text-[13px] md:text-[9px] lg:text-[10px] xl:text-[13px] font-semibold">
                    {data.desp3}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
