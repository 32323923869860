import React from "react";
import { ICON, PROD } from "../../../asset";

export default function Data() {
  const array = [
    {
      id: 1,
      img: PROD.p1,
      name: "TEI Utility I ",
      name1: "",
      desp: "Haiti",
    },
    {
      id: 2,
      img: PROD.p2,
      name: "TEI Galveston I",
      name1: "",
      desp: "Bali",
    },
    {
      id: 3,
      img: PROD.p3,
      name: "TEI Industrial Fund I ",
      name1: "(Sealy, TX)",
      desp: "Malaysia",
    },
    {
      id: 4,
      img: PROD.p4,
      name: "TEI Gateway I",
      name1: "",
      desp: "Haiti",
    },
    {
      id: 5,
      img: PROD.p5,
      name: "TEI Technology Fund I",
      name1: " (BSV Fund X)",
      desp: "Haiti",
    },
    {
      id: 6,
      img: PROD.p6,
      name: "TEI Land I ",
      name1: "(Round Top, TX)",
      desp: "Haiti",
    },
    {
      id: 7,
      img: PROD.p7,
      name: "TEI Industrial Fund II LP",
      name1: " ( Exline Fund III )",
      desp: "Haiti",
    },
    {
      id: 8,
      img: PROD.p8,
      name: "TEI Multifamily I ",
      name1: "(Bee Cave)",
      desp: "Haiti",
    },
    {
      id: 9,
      img: PROD.p9,
      name: "TEI Storage I LP",
      name1: " (Athens, TX)",
      desp: "Haiti",
    },
    {
      id: 10,
      img: PROD.p10,
      name: "TEI Multifamily II ",
      name1: "(Lakewood West)",
      desp: "Finance",
    },
    {
      id: 11,
      img: PROD.p11,
      name: "TEI Health I",
      name1: "",
      desp: "Finance",
    },
    {
      id: 12,
      img: PROD.p12,
      name: "TEI Storage II",
      name1: "(Tyler, TX)",
      desp: "Haiti",
    },
    {
      id: 13,
      img: PROD.p13,
      name: "TEI Home Tech I",
      name1: "",
      desp: "",
    },
    {
      id: 14,
      img: PROD.p14,
      name: "TEI Health II (Purity)",
      name1: "",
      desp: "",
    },
    {
      id: 15,
      img: PROD.p15,
      name: "TEI Energy I",
      name1: "(Clean Royalties)",
      desp: "",
    },
    {
      id: 16,
      img: PROD.p16,
      name: "TEI Multifamily III",
      name1: "(Mangum Oaks)",
      desp: "",
    },
    {
      id: 17,
      img: PROD.p17,
      name: "TEI Multifamily IV",
      name1: "(Wildwood Forest)",
      desp: "",
    },
    {
      id: 18,
      img: PROD.p18,
      name: "TEI Home Tech II",
      name1: "(Arovia)",
      desp: "",
    },
    {
      id: 19,
      img: PROD.p19,
      name: "TEI RAC (OWL) Fund I",
      name1: "(Rice)",
      desp: "",
    },
    {
      id: 20,
      img: PROD.p20,
      name: "TEI Technology Fund II",
      name1: "(BSV Fund XI)",
      desp: "",
    },
    {
      id: 21,
      img: PROD.p21,
      name: "TEI Utility II",
      name1: "(Powerline Inc)",
      desp: "",
    },
  ];
  return (
    <div className="w-full h-[70%] xl:py-10 lg:py-13 md:py-12 py-12 grid md:grid-cols-3 xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 gap-1 md:gap-5 px-4 ">
      {array.map((data) => (
        <div
          className="w-full pb-5 md:pb-0 h-full flex justify-center "
          key={data.id}
        >
          <div className="w-[90%] md:w-[85%] h-full rounded-lg shadow-customshadow hover:scale-105 transition-transform">
            <a
              className="w-full h-full"
              href="https://www.investnext.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-full h-[65%]">
                <img
                  src={data.img}
                  alt="user"
                  className="rounded-lg h-full w-full p-1 object-cover"
                />
              </div>
              <div className="w-full h-[35%] flex flex-col justify-center items-start">
                <div className="flex items-center justify-between w-full py-2 ">
                  <div>
                    <p className="text-dark font-semibold ml-2 text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px]">
                      {data.name}
                    </p>
                    <p className="text-dark font-semibold ml-2 text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px]">
                      {data.name1}
                    </p>
                  </div>
                  <img
                    src={ICON.right}
                    alt="images"
                    style={{ height: "5px" }}
                    className=" cursor-pointer pr-2"
                  />
                </div>
                {/* <p className="text-dark text-[5px]  md:text-[5px] lg:text-[8px] font-light ml-2">
      ({data.desp})
    </p> */}
              </div>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
