import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import NoPage from "../pages/noPage/NoPage";
import Navbar from "../components/header/Navbar";
import Footer from "../components/footer/Footer";
import About from "../pages/about/About";
import Product from "../pages/product/Product";
import Contact from "../pages/contact/Contact";
import Hustles from "../pages/hustles/Hustles";
import Partners from "../pages/partners/Partners";

export default function Layout() {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);

  return (
    <div className="min-h-[calc(100vh-8vh)] flex flex-col">
      <BrowserRouter>
        {/* Navbar */}
        <div className="w-full h-[13vh] flex items-center">
          <Navbar setOpen={setOpen} open={open} setId={setId} id={id} />
        </div>

        {/* Content Area - Takes Remaining Height */}
        <div className="flex-grow w-full">
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="investments" element={<Product />} />
              <Route path="contact" element={<Contact />} />
              <Route path="side_hustles" element={<Hustles />} />
              <Route path="fomo_fund" element={<Partners />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </div>

        {/* Footer - Sticks to Bottom */}
        <div className="w-full h-auto md:h-[5vh]">
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

